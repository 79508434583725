/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useCallback, useEffect, createRef } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { _formatNumberNotCarry, bnToDec } from '../utils'
const Home = ({ changeLanguage, locale }) => {
  let Timer;
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const ref = createRef(null)
  const [menu, setMenu] = useState(false)
  const [TotalMarkets, setTotalMarkets] = useState(undefined)
  const [CumulativeBorrowings, setCumulativeBorrowings] = useState(undefined)
  const [BLPStaking, setBLPStaking] = useState(undefined)
  const [status, setStatus] = useState(true)
  const changeTab = useCallback(() => {
    setStatus(!status)
  }, [status])
  const showMenu = () => {
    setMenu(!menu)
  }
  const Fetch = () => {
    try {
      fetch("https://app.dforce.network/general/dashboard")
        .then(response => response.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
          if (response?.totalSupply) {
            setTotalMarkets(response?.totalSupply)
          } else {
            setTotalMarkets(undefined)
          }
        });
      fetch("https://app.dforce.network/dashboard/total/info/")
        .then(response => response.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
          if (response?.status === 'OK') {
            setCumulativeBorrowings(response?.data?.accumulated_borrow)
          } else {
            setCumulativeBorrowings(undefined)
          }
        });
        fetch("https://app.unitus.finance/reward/stakedInfo")
        .then(response => response.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
          if (response?.status === 'OK') {
            setBLPStaking(response?.data?.TotalStake)
          } else {
            setBLPStaking(undefined)
          }
        });
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    Fetch()
    window.IntervalFetchApi = setInterval(() => {
      Fetch()
    }, 1000*15)
    return () => {
      clearInterval(window.IntervalFetchApi)
    }
  }, [])
  return (
    <>
      <Container>
        <Header>
          <TopBar>
            <a>
            <Log src="/images/logo.svg" />
            </a>
            <Nav>
            <li>
              <a href='https://unitus.gitbook.io/unitus' target="_blank" rel="noreferrer">Docs</a>
            </li>
            <ChangeLanguage
            >
              <span>Governance</span>
              <Changelist className={'showList'}>
                <a href="https://forum.unitus.finance/" target="_blank" rel="noopener noreferrer">Forum</a>
                <a  target="_blank" rel="noopener noreferrer">Snapshot</a>
              </Changelist>
            </ChangeLanguage>
            <ChangeLanguage
            >
              <span>Community</span>
              <Changelist className={'showList'}>
                <a href="https://twitter.com/unitusfi" target="_blank" rel="noopener noreferrer">Twitter</a>
                <a href="https://t.me/unitusfinance" target="_blank" rel="noopener noreferrer">Telegram</a>
                <a href="https://discord.gg/b3xdKjRD3Q" target="_blank" rel="noopener noreferrer">Discord</a>
                <a href="https://unitus.medium.com" target="_blank" rel="noopener noreferrer">Medium</a>
                {/* <a  target="_blank" rel="noopener noreferrer">Friend.Tech</a> */}
              </Changelist>
            </ChangeLanguage>
            <li>
              <a href='https://unitus.gitbook.io/unitus/overview/faq' target="_blank" rel="noreferrer">FAQ</a>
            </li>
            <li className='join'>
                <a href='https://app.unitus.finance/' className='noHover' target='_blank' rel="noreferrer">{fmt({ id: 'Launch_APP' })}</a>
              </li>
              {/* <ChangeLanguage onClick={showList}>
                <img
                  src={locale === 'en' ? '/images/EN.svg' : '/images/CN.svg'}
                />
                <span>{locale === 'en' ? 'English' : '简体中文'}</span>
                <b></b>
                <Changelist ref={ref} className={open ? '' : 'hide'}>
                  <li onClick={() => changeLanguage('en')}>
                    <img src="/images/EN.svg" />
                    <span>English</span>
                  </li>
                  <li onClick={() => changeLanguage('cn')}>
                    <img src="/images/CN.svg" />
                    <span>简体中文</span>
                  </li>
                </Changelist>
              </ChangeLanguage> */}
            </Nav>
            <Menu onClick={showMenu}>
                <img src="/images/m-menu.svg" alt="" />
            </Menu>
          </TopBar>
          <Slogan>
            <MTitle>The Next-Generation Multichain Money Market</MTitle>
            <SubTitle>Connecting Assets, Bridging Chains</SubTitle>
            <SloLink>
              <a href='https://app.unitus.finance/' target='_blank' rel="noreferrer">
                {fmt({ id: 'Launch_APP' })}
                <img src="/images/arrive.svg" alt="" srcset="" />
              </a>
              {/* <a className='hover'>
                {fmt({ id: 'ComingSoon' })}
                <img src="/images/arrive.svg" alt="" srcset="" />
              </a> */}
            </SloLink>
            <ChainList>
              <Chain src="/images/chain/ETH.svg" alt="Ethereum Mainnet"></Chain>
              <Chain src="/images/chain/Arbitrum.svg" alt="Arbitrum"></Chain>
              <Chain src="/images/chain/Optimism.svg" alt="Optimism"></Chain>
              <Chain src="/images/chain/BSC.svg" alt="BSC"></Chain>
              <Chain src="/images/chain/Polygon.svg" alt="Polygon"></Chain>
              {/* <Chain src="/images/chain/Avalanche.svg" alt="Avalanche"></Chain>
              <Chain src="/images/chain/Kava.svg" alt="Kava"></Chain> */}
              <Chain src="/images/chain/Conflux.svg" alt="Conflux"></Chain>
            </ChainList>
          </Slogan>
        </Header>
        <DataWarp>
          <DataInner>
            <Data>
              <Value>{TotalMarkets ? `$${_formatNumberNotCarry(bnToDec(TotalMarkets, 18), 'abbr')}` : '...'}</Value>
              <Title>Total Markets</Title>
            </Data>
            <Data>
              <Value>{CumulativeBorrowings ? `$${_formatNumberNotCarry(bnToDec(CumulativeBorrowings, 18), 'abbr')}` : '...'}</Value>
              <Title>Cumulative Borrowings</Title>
            </Data>
            <Data>
              <Value>{BLPStaking ? `$${_formatNumberNotCarry(bnToDec(BLPStaking, 18), 'abbr')}` : '...'}</Value>
              <Title>BLP Staking</Title>
            </Data>
          </DataInner>
        </DataWarp>
        <LinkToDapp>
          <LinkWrap>
            <img src="/images/supply.svg" alt="supply" />
            <LinkMTitle>Supply</LinkMTitle>
            <LinkSubTitle>Earn passive yield on your idle funds.</LinkSubTitle>
            {/* <LinkTo>
              Supply Token
              <img src="/images/LinkArrive.svg" alt="" />
            </LinkTo> */}
          </LinkWrap>
          <LinkWrap>
            <img src="/images/borrow.svg" alt="borrow" />
            <LinkMTitle>Borrow</LinkMTitle>
            <LinkSubTitle>Borrow across all chains without selling your collateral assets.</LinkSubTitle>
            {/* <LinkTo>
              Borrow Now
              <img src="/images/LinkArrive.svg" alt="" srcset="" />
            </LinkTo> */}
          </LinkWrap>
          <LinkWrap>
            <img src="/images/farm.svg" alt="farm" />
            <LinkMTitle>Earn</LinkMTitle>
            <LinkSubTitle>Earn UTS through liquidity mining on your deposits and borrows.</LinkSubTitle>
            {/* <LinkTo>
              Earn Now
              <img src="/images/LinkArrive.svg" alt="" srcset="" />
            </LinkTo> */}
          </LinkWrap>
          <LinkWrap>
            <img src="/images/vote.svg" alt="vote" />
            <LinkMTitle>Boost</LinkMTitle>
            <LinkSubTitle>Boost UTS emissions by providing liquidity for UTS/DF, UTS/USX.</LinkSubTitle>
            {/* <LinkTo>
              Learn More
              <img src="/images/LinkArrive.svg" alt="" srcset="" />
            </LinkTo> */}
          </LinkWrap>
        </LinkToDapp>
        <HowItWorks>
          <SectionTitle>How It Works?</SectionTitle>
          <Tabs>
            <Tab onClick={()=>{setStatus(true)}} className={status ? 'active' : ''}>For Lenders & Borrowers</Tab>
            <Tab onClick={()=>{setStatus(false)}} className={status ? '' : 'active'}>For BLP Depositors</Tab>
          </Tabs>
          {
            status ?
              (
                <HowContent>
                  <ConText>
                    <img src="/images/how1.svg" alt="" />
                    <Text>Supply assets as collateral and generate a steady yield.</Text>
                  </ConText>
                  <ConText>
                    <img src="/images/how2.svg" alt="" />
                    <Text>Borrow seamlessly on any chain without selling your collateral assets.</Text>
                  </ConText>
                  <ConText>
                    <img src="/images/how3.svg" alt="" />
                    <Text>Repay your loan to reclaim your collateral assets.</Text>
                  </ConText>
                </HowContent>
              )
              : (
                <HowContent>
                  <ConText>
                    <img src="/images/how4.svg" alt="" />
                    <Text>Provide liquidity for UTS/DF, UTS/USX to earn emissions.</Text>
                  </ConText>
                  <ConText>
                    <img src="/images/how5.svg" alt="" />
                    <Text>Stake BLP (Bonded Liquidity Provisioning) to boost your emissions.</Text>
                  </ConText>
                  <ConText>
                    <img src="/images/how6.svg" alt="" />
                    <Text>Claim your rewards in UTS token.</Text>
                  </ConText>
                </HowContent>
              )
          }

        </HowItWorks>
        <Dao>
          <DLeft>
            <DTitle>A Community-Driven DAO</DTitle>
            <DSubTitle>UTS holder will vote to facilitate decentralized decision-making and governance for Unitus.</DSubTitle>
          </DLeft>
          <DRight>
            <Sec>
              <STitle href="https://forum.unitus.finance/" target='_blank' rel="noreferrer">
                Forum
                <img src="/images/arrive.svg" alt="" />
              </STitle>
              <SSubTitle>
                Engage with community and publish your proposal for discussions.
              </SSubTitle>
            </Sec>
            <Sec>
              <STitle>
              Snapshot
                {/* <img src="/images/arrive.svg" alt="" srcset="" /> */}
              </STitle>
              <SSubTitle>
              Submit your proposal for the community to vote on approvals.
              </SSubTitle>
            </Sec>
            {/* <Sec>
              <STitle>
              Delegation
                <img src="/images/arrive.svg" alt="" srcset="" />
              </STitle>
              <SSubTitle>
              Delegate to governors of your choice to vote on your behalf.
              </SSubTitle>
            </Sec> */}
          </DRight>
        </Dao>
        <Security>
          <SectionTitle>Security</SectionTitle>
          <SecurityList>
            <SecuCon href="https://www.trailofbits.com/" target='_blank' rel="noreferrer">
            <img className='TrailBits' src="/images/TrailBits.svg" alt="TrailBits" />
            </SecuCon>
            <SecuCon href="https://consensys.io/diligence" target='_blank' rel="noreferrer">
            <img src="/images/Diligence.svg" alt="Diligence" />
            </SecuCon>
            <SecuCon href="https://t.co/N2fFD8HPbm" target='_blank' rel="noreferrer">
            <img src="/images/MixBytes.svg" alt="MixBytes" />
            </SecuCon>
            <SecuCon href="https://t.co/IvfIacVGqc" target='_blank' rel="noreferrer">
            <img src="/images/CERTORA.svg" alt="CERTORA" />
            </SecuCon>
            <SecuCon href="https://t.co/Y6aJULx0hv" target='_blank' rel="noreferrer">
            <img src="/images/Immunefi.svg" alt="Immunefi" />
            </SecuCon>
            <SecuCon href="https://t.co/Im4Bd8Qc0f" target='_blank' rel="noreferrer">
            <img src="/images/CERTIK.svg" alt="CERTIK" />
            </SecuCon>
            <SecuCon href="https://www.insuredao.fi/" target='_blank' rel="noreferrer">
            <img src="/images/InsureDAO.svg" alt="InsureDAO" />
            </SecuCon>
          </SecurityList>
        </Security>
        <Ecosystem>
          <SectionTitle>Ecosystem</SectionTitle>
          <EcosystemList>
            <EcosCon>
            <img src="/images/Arbitrum.svg" alt="Arbitrum" />
            Arbitrum
            </EcosCon>
            <EcosCon>
            <img src="/images/Optimism.svg" alt="Optimism" />
            Optimism
            </EcosCon>
            <EcosCon>
            <img src="/images/dForce.svg" alt="dForce" />
            dForce
            </EcosCon>
            <EcosCon>
            <img src="/images/MakerDAO.svg" alt="MakerDAO" />
            MakerDAO
            </EcosCon>
            <EcosCon>
            <img src="/images/LidoFinance.svg" alt="Lido Finance" />
            Lido Finance
            </EcosCon>
            <EcosCon>
            <img src="/images/RocketPool.svg" alt="Rocket Pool" />
            Rocket Pool
            </EcosCon>
            <EcosCon>
            <img src="/images/LayerZero.svg" alt="LayerZero" />
            LayerZero
            </EcosCon>
            <EcosCon>
            <img src="/images/Celer.svg" alt="Celer" />
            Celer
            </EcosCon>
            <EcosCon>
            <img src="/images/ChainLink.svg" alt="ChainLink" />
            ChainLink
            </EcosCon>
            <EcosCon>
            <img src="/images/Pyth.svg" alt="Pyth" />
            Pyth
            </EcosCon>
          </EcosystemList>
        </Ecosystem>
        <BlueBg></BlueBg>
        <FooterTop>
          <ContentTop>
            <LeftLogo>
              <TmpTop>
                <a>
                  <img src="/images/logo.svg" alt="" />
                </a>
                <MenuLink>
                  <a href="mailto:contact@unitus.finance" target="_blank" rel="noreferrer">contact@unitus.finance</a>
                </MenuLink>
              </TmpTop>
            </LeftLogo>
            <RightMenuWrap>
              <RightMenu>
                <MenuLink>
                  <a href='https://unitus.gitbook.io/unitus' target="_blank" rel="noopener noreferrer">Documentation</a>
                </MenuLink>
                <MenuLink>
                  <a href='https://unitus.gitbook.io/unitus/tokenomics/uts-token' target="_blank" rel="noopener noreferrer">Tokenomics</a>
                </MenuLink>
                <MenuLink>
                  <a  target="_blank" rel="noopener noreferrer">Technical Paper</a>
                </MenuLink>
                <MenuLink>
                  <a href='https://github.com/dforce-network/documents/tree/master/audit_report/Lending' target="_blank" rel="noopener noreferrer">Audits</a>
                </MenuLink>
              </RightMenu>
              <RightMenu>
                <MenuLink>
                  <a href="https://dforce.network/" target="_blank" rel="noopener noreferrer">dForce</a>
                </MenuLink>
                <MenuLink>
                  <a href="https://usx.finance/" target="_blank" rel="noopener noreferrer">USX Finance</a>
                </MenuLink>
              </RightMenu>
              <RightMenu>
                <MenuLink>
                  <a href="https://twitter.com/unitusfi" target="_blank" rel="noopener noreferrer">Twitter</a>
                </MenuLink>
                <MenuLink>
                  <a href="https://t.me/unitusfinance" target="_blank" rel="noopener noreferrer">Telegram</a>
                </MenuLink>
                <MenuLink>
                  <a href="https://discord.gg/b3xdKjRD3Q" target="_blank" rel="noopener noreferrer">Discord</a>
                </MenuLink>
                <MenuLink>
                  <a href="https://unitus.medium.com" target="_blank" rel="noopener noreferrer">Medium</a>
                </MenuLink>
              </RightMenu>
              <RightMenu>
                <MenuLink>
                  <a href="https://forum.unitus.finance/" target="_blank" rel="noreferrer">Forum</a>
                </MenuLink>
                <MenuLink>
                  <a  target="_blank" rel="noreferrer">Snapshot</a>
                </MenuLink>
                {/* <MenuLink>
                  <a  target="_blank" rel="noreferrer">Delegation</a>
                </MenuLink>
                <MenuLink>
                  <a  target="_blank" rel="noreferrer">Contact</a>
                </MenuLink> */}
              </RightMenu>
            </RightMenuWrap>
          </ContentTop>
        </FooterTop>
      </Container>
      <Mmenu className={menu ? 'show' : ''} onClick={showMenu}>
        <Mnav>
          <CloseWarp>
            <a href='https://unitus.gitbook.io/unitus' target="_blank" rel="noreferrer">Docs</a>
            <img src="/images/Close.svg" alt="Close" width="18" onClick={()=>{setMenu(false)}} />
          </CloseWarp>
          
          <a>Governance</a>
          <ChildList>
            <a href="https://forum.unitus.finance/" target="_blank" rel="noreferrer">Forum</a>
            <a  target="_blank" rel="noreferrer">Snapshot</a>
          </ChildList>
          <a>Community</a>
          <ChildList>
            <a href="https://twitter.com/unitusfi" target="_blank" rel="noreferrer">Twitter</a>
            <a href="https://t.me/unitusfinance" target="_blank" rel="noreferrer">Telegram</a>
            <a href="https://discord.gg/b3xdKjRD3Q" target="_blank" rel="noreferrer">Discord</a>
            <a href="https://unitus.medium.com" target="_blank" rel="noopener noreferrer">Medium</a>
            {/* <a  target="_blank" rel="noreferrer">Friend.Tech</a> */}
          </ChildList>
          <a href='https://unitus.gitbook.io/unitus/overview/faq' target="_blank" rel="noopener noreferrer">FAQ</a>
        </Mnav>
      </Mmenu>
    </>
  );
};
export default Home;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  background-image: url('/images/body-bg.svg');
  background-repeat: repeat-y;
  background-position: center;
  background-size: contain;
`;
const Menu = styled.div`
display: none;
@media (max-width: 1200px) {
  display: block;
  width: 26px;
  height: 26px;
  margin-left: 8px;
  img {
    width: 26px;
    height: 26px;
  }
}
`;
const Mnav = styled.nav`
  display: none;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    background-color: rgba(25, 27, 31, 1);
    width: calc( 100% - 24px);
    position: absolute;
    right: 12px;
    top: 38px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    box-sizing: border-box;
    a {
      color: #FFF;
      font-size: 12px;
      font-weight: 500;
      line-height: 36px;
    }
  }
`;
const CloseWarp =styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ChildList = styled.div`
 display: flex;
 flex-direction: column;
  padding: 16px 12px;
  border-radius: 8px;
  background: rgba(18, 18, 18, 1);
  a{
    font-size: 12px;
    font-weight: 600;
    line-height: 26px;
  }
;
`
const Mmenu = styled.section`
  display: none;
  @media (max-width: 1200px) {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #00000099;
    &.show {
      display: flex;
      flex-direction: column;
    }
  }
`;
const Header = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background-image: url('/images/banner.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
  @media (max-width: 1200px) {
    background-position: center;
  }
`;
const TopBar = styled.header`
  width: 1200px;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 12px;
    height: 38px;
    line-height: 38px;
  }
`;
const Log = styled.img`
  width: 124px;
  height: 32px;
  @media (max-width: 1200px) {
    width: 83px;
    height: 22px;
  }
`;
const Slogan = styled.article`
  width: 1200px;
  display: flex;
  flex-direction:column;
  justify-content: space-between;
  align-items: center;
  padding:90px 0 40px;
  gap: 20px;
  @media (max-width: 1200px) {
    width:100%;
    gap: 8px;
    padding:30px 0 16px;
    flex-direction:column;
  }
`;
const MTitle = styled.div`
  font-size: 64px;
  line-height: 80px;
  font-weight: 700;
  text-align: center;
  /* margin-bottom: 24px; */
  max-width: 830px;
  @media (max-width: 1200px) {
    font-size: 24px;
    line-height: 36px;
    /* margin-bottom: 0; */
  }
`
const SubTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: #CCC;
  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 20px;
  }
`
const SloLink = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  font-weight: 600;
  margin: 34px auto;
  /* .hover{
    display: none;
  }
  &:hover{
        .hover{
          display: flex;
        }
        .noHover{
          display: none;
        }
      } */
  a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 18px 32px;
    cursor: pointer;
    border-radius: 10px;
    background: var(--Gradient-color, linear-gradient(90deg, #34AEF5 0%, #286FF8 100%));
    gap: 8px;
    img {
      display: inline-block;
      margin-top: 2px;
      width: 23px;
      height: 22px;
    }
  };
  @media (max-width: 1200px) {
    width: 100%;
    margin: 0 auto;
    a {
      border-radius: 6px;
      font-size:12px;
      padding:8px 12px;
      img {
        display: inline-block;
        width: 14px;
        height: 14px;
      }
    };
  }
`;
const ChainList = styled.ul`
  display: flex;
  padding-top: 46px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  @media (max-width: 1200px) {
    padding-top: 16px;
    gap: 8px;
  }
`
const Chain = styled.img`
  display: inline-block;
  width: 56px;
  height: 56px;
  @media (max-width: 1200px) {
    width: 23px;
    height: 23px;
  }
`
const DataWarp = styled.div`
  display: flex;
  width: 100%;
  padding: 40px 0px;
  margin-bottom: 60px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  background-image: url('/images/Data.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  backdrop-filter: blur(250px);
  @media (max-width: 1200px) {
    padding: 16px 0;
    margin:0 auto 16px;
  }
`
const DataInner = styled.ul`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  @media (max-width: 1200px) {
    width: 100%;
  }
`
const Data = styled.li`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  @media (max-width: 1200px) {
    gap: 8px;
  }
`
const Value = styled.div`
  font-size: 42px;
  font-weight: 700;
  line-height: 42px; 
  @media (max-width: 1200px) {
    font-size: 16px;
    line-height: 21px; 
  }
`
const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px; 
  @media (max-width: 1200px) {
    font-size: 10px;
    line-height: 16px;
  }
`
const LinkToDapp = styled.div`
  width: 1200px;
  margin-bottom:88px ;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 16px;
    margin: 0 auto 36px;
    box-sizing: border-box;
    flex-direction: column;
    gap: 12px;
  }
`
const LinkWrap = styled.section`
  flex-basis: 588px;
  box-sizing: border-box;
  padding: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  background: #191B1F;
  img{
    width: 48px;
  }
  @media (max-width: 1200px) {
    flex-basis: 100%;
    height: auto;
    padding: 20px;
    border-radius: 12px;
    gap: 16px;
    img{
      width: 32px;
    }
  }
`
const LinkMTitle = styled.div`
  color: #FFF;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  @media (max-width: 1200px) {
    font-size: 14px;
    line-height: 14px;
  }
`
const LinkSubTitle = styled.div`
  color: #CCC;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 20px;
  }
`
const LinkTo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #2c81f7;
  cursor: pointer;
  img{
    width: 32px;
  }
  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 12px;
    img{
      width: 12px;
    }
  }
`
const HowItWorks = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-bottom: 88px;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 16px;
    gap: 12px;
    margin:0 auto 36px;
  }
`
const SectionTitle = styled.div`
  color: #FFF;
  font-size: 42px;
  font-weight: 600;
  line-height: 42px;
  @media (max-width: 1200px) {
    font-size: 20px;
    line-height: 20px;
  }
`
const Tabs = styled.div`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 20px;
  background: #191B1F;
  @media (max-width: 1200px) {
    border-radius: 8px;
    padding: 4px;
  }
`
const Tab = styled.div`
  display: flex;
  width: 248px;
  padding: 18px 32px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  &.active{
    border-radius: 12px;
    background: var(--Gradient-color, linear-gradient(90deg, #34AEF5 0%, #286FF8 100%));
  }
  @media (max-width: 1200px) {
    width: 165px;
    padding: 10px 12px;
    font-size: 12px;
    &.active{
      border-radius: 6px;
    }
  }
`
const HowContent = styled.div`
  display: flex;
  align-items: flex-start;
  
  gap: 24px;
  @media (max-width: 1200px) {
    gap: 16px;
    flex-direction: column;
  }
`
const ConText = styled.div`
  display: flex;
  flex: 1;
  padding: 56px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`
const Text = styled.div`
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
`
const Dao = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 88px;
  @media (max-width: 1200px) {
    width: 100%;
    flex-direction: column;
    padding: 0 16px;
    margin: 0 auto 16px;
    box-sizing: border-box;
    flex-direction: column;
    gap: 14px;
  }
`
const DLeft = styled.div`
  display: flex;
  width: 588px;
  height: 360px;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  border-radius: 20px;
  background-image: url('/images/DAO.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  @media (max-width: 1200px) {
    width: 100%;
    height: 220px;
    padding: 23px;
    margin: 0 auto 0;
    box-sizing: border-box;
  }
`
const DTitle = styled.div`
  color: #FFF;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  @media (max-width: 1200px) {
      font-size: 23px;
  }
`
const DSubTitle = styled.div`
  color: #CCC;
font-size: 16px;
font-weight: 600;
line-height: 32px;
@media (max-width: 1200px) {
    font-size: 12px;
    line-height: 21px;
}
`
const DRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 360px;
  @media (max-width: 1200px) {
    width: 100%;
    height: auto;
    margin: 0 auto 36px;
    box-sizing: border-box;
    gap:14px;
  }
`
const Sec = styled.div`
  display: flex;
  flex: 1;
  width: 588px;
  padding: 20px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  border-radius: 20px;
  border: 0px solid rgba(255, 255, 255, 0.08);
  background: #191B1F;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 20px;
    border-radius: 12px;
    gap: 9px
  }
`
const STitle = styled.a`
display: flex;
align-items: center;
  color: #FFF;
font-size: 24px;
font-weight: 700;
line-height: 24px; 
gap: 4px;
img{
  width: 23px;
}
@media (max-width: 1200px) {
  font-size: 14px;
  line-height: 14px;
  img{
    width: 14px;
  }
}
`
const SSubTitle = styled.div`
  color: #CCC;
font-size: 16px;
font-weight: 600;
line-height: 16px; 
@media (max-width: 1200px) {
  font-size: 12px;
  line-height: 24px;
}
`
const Security = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-bottom: 88px;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 16px;
    margin: 0 auto 16px;
    box-sizing: border-box;
    flex-direction: column;
    gap: 12px;
  }
`
const SecurityList = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
  row-gap: 40px;
  @media (max-width: 1200px) {
    gap: 20px;
  }
`
const SecuCon = styled.a`
  display: flex;
  width: 300px;
  box-sizing: border-box;
  padding: 24px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  @media (max-width: 1200px) {
    width: 29%;
    img{
      width: 80%;
      &.TrailBits{
        width: 38%;
      }
    }
  }
`
const Ecosystem = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 16px;
    margin: 0 auto 16px;
    box-sizing: border-box;
    flex-direction: column;
    gap: 12px;
  }
`
const EcosystemList = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
  row-gap: 24px;
  font-weight: 600;
  @media (max-width: 1200px) {
    gap: 12px;
  }
`
const EcosCon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  padding: 40px 0;
  gap: 16px;
  
  @media (max-width: 1200px) {
    width: 31%;
    padding: 20px 0;
    gap:8px;
    font-size: 12px;
    line-height: 16px;
    img{
      width: 28px;
    }
  }
`
const BlueBg = styled.div`
  width: 1920px;
  height: 368px;
  margin: 0 auto;
  background-image: url('/images/Blue.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  @media (max-width: 1200px) {
    width: 100%;
    height: 71px;
  }
`
// const ChangeLanguage = styled.li`
//   display: flex;
//   align-items: center;
//   cursor: pointer;
//   position: relative;
//   img {
//     width: 24px;
//     height: 16px;
//     margin-right: 6px;
//   }
//   span {
//     margin-right: 6px;
//   }
//   b {
//     display: block;
//     width: 0;
//     height: 0;
//     border-left: 4px solid transparent;
//     border-right: 4px solid transparent;
//     border-top: 6px solid #fff;
//   }
// `;
// const Changelist = styled.ul`
//   /* display:none; */
//   &.hide {
//     display: none;
//   }
//   position: absolute;
//   left: 50%;
//   top: 70px;
//   transform: translateX(-50%);
//   display: flex;
//   flex-direction: column;
//   background-color: #fff;
//   padding: 0 20px;
//   border-radius: 6px;
//   li {
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     width: max-content;
//     height: 45px;
//     line-height: 45px;
//     margin: 0 !important;
//     img {
//       width: 24px;
//       height: 16px;
//       margin-right: 6px;
//     }
//     span {
//       font-size: 14px;
//       margin-right: 6px;
//       color: #080D27;
//     }
//     &:hover {
//       span {
//         color: #000;
//       }
//     }
//   }
// `;
const ChangeLanguage = styled.li`
  cursor: pointer;
  /* margin-right: 24px; */
  position: relative;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 16px;
  .showList {
    display: none;
  }
  &:hover {
    .showList {
      display: flex;
    }
  }
`
const Changelist = styled.ul`
  &.hide {
    display: none;
  }
  position: absolute;
  left: 50%;
  top: 65px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  background-color: #191B1F;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  a {
    line-height: 36px;
  }
`;
const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  li {
    padding:12px 24px; 
    /* a {
      color: #fff;
    } */
    
    &.join{
      a{
        display: inline-block;
        cursor: pointer;
        width: 100%;
        color: #fff;
        padding: 0 24px;
        height: 40px;
        line-height: 40px;
        border-radius: 10px;
        background: var(--Gradient-color, linear-gradient(90deg, #34AEF5 0%, #286FF8 100%));
      }
    }
  }
  @media (max-width: 1200px) {
    display: none;
  }
`;
const FooterTop = styled.footer`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  background: rgba(18, 18, 18, 0.7);
  margin-top: -225px;
  @media (max-width: 1200px) {
    width: 100vw;
    margin-top: -20px;
  }
`
const ContentTop = styled.section`
  display: flex;
  width: 1200px;
  padding-top: 40px;
  padding-bottom: 16px;
  @media (max-width: 1200px) {
    width: 100%;
    flex-direction: column;
    padding: 16px;
    padding-bottom: 0px;
  }
`
const LeftLogo = styled.section`
  margin-right: 240px;
  @media (max-width: 1200px) {
    margin-right: 0px;
    margin-bottom: 16px;
  }
`
const TmpTop = styled.section`
  img {
    @media (max-width: 1200px) {
      height: 22px;
    }
  }
  @media (max-width: 1200px) {
    margin-bottom: 12px;
  }
`

const RightMenuWrap = styled.section`
  display: flex;
  flex: 1;
  justify-content: space-between;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`
const RightMenu = styled.section`
  width:260px;
  @media (max-width: 1200px) {
    margin-bottom: 16px;
  }
`
const MenuLink = styled.section`
  a {
    color: rgba(255, 255, 255, 0.60);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px;
    transition: 0.3s;
    &:hover {
      color: #ffffff;
    }
    @media (max-width: 1200px) {
      font-size: 12px;
      line-height: 24px;
    }
  }
`
